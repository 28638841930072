"use client"

import { Button } from "@deltagreen/react-library"
import { IconX } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useSessionStorage } from "react-use"

import { useIsMounted } from "@/utils/hooks/useIsMounted"
import { cn } from "@/utils/tailwind"

type Props = {
  className?: string
  from: Date
  to: Date
}

export function MaintenanceBarClient(props: Props) {
  const { className, from, to } = props

  const [maintenanceNoticeDismissed, setMaintenanceNoticeDismissed] = useSessionStorage(
    "maintenance-notice-dismissed",
    false,
  )

  const isMounted = useIsMounted()
  if (!isMounted) {
    return null
  }

  if (maintenanceNoticeDismissed) {
    return null
  }

  return (
    <header
      className={cn(
        "fixed left-0 right-0 top-0 z-[2000] flex min-h-16 items-center justify-center gap-4 bg-persian-green-200 py-1 pl-4 pr-10 sm:min-h-10",
        className,
      )}
    >
      <p className="text-sm font-normal text-persian-green-700">
        Od {dayjs(from).format("DD.MM. HH:mm")} do {dayjs(to).format("DD.MM. HH:mm")} se bude konat plánovaná odstávka,
        během které pro vás budeme Protea vylepšovat. V tomto čase bude portál Proteus nedostupný, a řízení vaší FVE
        dočasně pozastavíme.
      </p>

      <Button
        size="icon"
        variant="ghost"
        className="group absolute right-2 h-6 w-6 rounded hover:bg-persian-green-300"
        onClick={() => setMaintenanceNoticeDismissed(true)}
      >
        <IconX className="h-4 w-4 cursor-pointer text-persian-green-600 group-hover:text-persian-green-900" />
        <div className="sr-only">Close maintenance bar</div>
      </Button>
    </header>
  )
}
